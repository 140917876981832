<template>
  <b-modal
    id="import-tiss-xml-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
    @show="getImportTissXML"
  >
    <div class="modal-header d-flex flex-row justify-content-between">
      <p class="modal-title">Importar XML de resposta da operadora</p>
      <Close class="icon" @click="closeModal" />
    </div>

    <div class="modal-body">
      <b-row class="md-content">
        <div @drop="dragFile" class="area" @click="$refs.FileInput.click()">
          <div v-if="!file">
            Clique aqui ou solte o arquivo nessa área para selecionar...
          </div>
          <span v-if="file" class="file-name">{{ file.name }}</span>
          <div>
            <b-button
              class="buttons"
              v-if="file"
              variant="outline-danger"
              @click="clearSelectedFile"
            >
              Cancelar
            </b-button>
            <b-button
              v-if="file"
              class="ml-2"
              size="lg"
              variant="primary"
              @click="uploadFile"
            >
              Enviar
            </b-button>
          </div>
        </div>
        <input
          class="d-none"
          ref="FileInput"
          type="file"
          accept=".xml"
          @change="onFileSelect"
        />
      </b-row>
      <perfect-scrollbar>
        <b-row class="all-imports">
          <b-col
            cols="8"
            v-for="xmlImport in imports"
            :key="xmlImport.id"
            class="progress-content"
          >
            <div class="filename">
              <div class="d-flex flex-column">
                <span class="d-inline-block text-truncate xml-name">
                  <strong>XML TISS: </strong>
                  {{ xmlImport.file_name }}
                </span>
                <span>
                  <strong>Finalizado em: </strong>
                  {{ moment(xmlImport.finished_at).format('DD/MM/YYYY [às] HH:mm') }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </perfect-scrollbar>
    </div>

    <div class="modal-footer">
      <div class="w-100">
        <b-button variant="primary" class="float-right" @click="closeModal">
          Fechar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'ImportTissXMLModal',
  components: {
    Close
  },
  props: {
    getBatches: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      file: null,
      imports: [],
      wasImported: false
    }
  },
  methods: {
    async getImportTissXML() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getImportTissXML(this.clinic.id)
        this.imports = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    closeModal(ev) {
      this.clearSelectedFile(ev)
      this.imports = []
      if (this.wasImported) {
        this.getBatches()
      }
      this.wasImported = false
      this.$bvModal.hide('import-tiss-xml-modal')
    },
    dragFile(e) {
      e.preventDefault()
      e.stopPropagation()
      this.file = e.dataTransfer.files[0]
    },
    clearSelectedFile(ev) {
      if (ev?.type !== 'hidden') {
        ev.stopPropagation()
        this.$refs.FileInput.value = null
      }
      this.file = null
    },
    onFileSelect(e) {
      this.file = e.target.files[0]
    },
    async uploadFile(ev) {
      ev.stopPropagation()
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('clinic_id', this.clinic.id)
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.importTissBatchXML(this.clinic.id, formData)
        this.clearSelectedFile(ev)
        this.$toast.success('Arquivo enviado com sucesso')
        this.wasImported = true
        this.imports.unshift(response.data)
      } catch (error) {
        const message = error.response.data.message
        if (
          [
            'Arquivo inválido',
            'XML inválido',
            'Lote não encontrado',
            'Item não encontrado'
          ].includes(message)
        ) {
          this.$toast.error(message)
          return
        }
        this.$toast.error(
          'Não foi possível realizar essa ação, caso o problema persista, contate o suporte.'
        )
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>

<style lang="scss">
#import-tiss-xml-modal {
  .modal-content {
    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(----type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .area {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 75%;
        min-height: 32vh;
        border: 1px dashed var(--type-placeholder);
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--neutral-100);
        margin-bottom: 4%;
      }

      .file-name {
        padding: 10px;
        color: var(--type-placeholder);
      }
    }

    .md-content {
      margin: 0 auto;
      padding: 30px 20px 0 20px;
    }

    .all-imports {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      font-size: 0.95em;
      max-height: 30vh;

      .progress-content {
        border-radius: 8px;
        padding: 20px;
        background-color: var(--neutral-100);
        margin-bottom: 3%;
      }

      .filename {
        text-align: left;
        .xml-name {
          max-width: 90%;
        }
      }
    }
  }
}
</style>

